import { v4 as uuidv4 } from 'uuid'
import {
  getMaxQuantity,
  getRemaining,
  IGiftItemResponse,
} from '@one-tree/library'
import { DeliveryMethod, RecipientType } from '../types/itemTypes'
import { IOrderPostData, IPreparedItem } from '../types/APITypes'
import { IBasketItem, IPurchaserData } from '../types/dataTypes'
import { isItemValid } from '../pages/personalise/helpers/recipientValidity'
import { IOrganisationResponse } from '../types/organisationTypes'

const fullAddress = (
  addressLineOne?: string,
  addressLineTwo?: string,
  town?: string,
  postcode?: string,
): string => {
  if (addressLineOne && postcode) {
    const lineTwo = addressLineTwo ? `${addressLineTwo}, ` : ''
    const lineTown = town ? `${town}, ` : ''

    return `${addressLineOne}, ${lineTwo}${lineTown}${postcode}`
  }
  return ''
}

const addressFullName = (firstName?: string, lastName?: string): string => {
  const space = firstName && lastName ? ' ' : ''
  return `${firstName || ''}${space}${lastName || ''}`
}

const getFullAddress = (name?: string, address?: string): string => {
  const space = `${name && address ? ', ' : ''}`
  return `${name}${space}${address}`
}

export const getDeliveryInfo = (
  basketItem: IBasketItem,
  purchaser: IPurchaserData,
  auth?: boolean,
): string => {
  const {
    email,
    addressLineOne,
    addressLineTwo,
    town,
    postcode,
    deliveryMethod,
    recipientType,
    deliveryFirstName,
    deliveryLastName,
  } = basketItem.recipient
  const {
    purchaserEmail,
    purchaserAddressLineOne,
    purchaserAddressLineTwo,
    purchaserTown,
    purchaserPostcode,
    purchaserFirstName,
    purchaserLastName,
  } = purchaser

  // make email/purchaser the default, since TESO uses those settings as default
  const isPost = deliveryMethod === DeliveryMethod.Post
  const isRecipient = recipientType === RecipientType.Recipient
  const isPrint = deliveryMethod === DeliveryMethod.Print

  const purchaserAddress = getFullAddress(
    addressFullName(purchaserFirstName, purchaserLastName),
    fullAddress(
      purchaserAddressLineOne,
      purchaserAddressLineTwo,
      purchaserTown,
      purchaserPostcode,
    ),
  )

  const wording = auth ? ' customer\'s' : ' your'
  const purchaserPostWording = purchaserAddress || ` ${wording} postal address`
  const purchaserEmailWording = purchaserEmail || ` ${wording} email address`
  const purchaserWording = isPost ? purchaserPostWording : purchaserEmailWording

  const purchaserDeliveryInfo = deliveryMethod ? purchaserWording : ''

  const recipientAddress = getFullAddress(
    addressFullName(deliveryFirstName, deliveryLastName),
    fullAddress(addressLineOne, addressLineTwo, town, postcode),
  )

  const renderEmail = email || ''
  const renderPost = recipientAddress || ''

  const recipientDeliveryInfo = isPost ? renderPost : renderEmail

  const deliveryInfo = isRecipient
    ? recipientDeliveryInfo
    : purchaserDeliveryInfo

  return isPrint ? 'Print' : deliveryInfo
}

export const getToPlaceholder = (name: string): string => `To ${name}`
export const messagePlaceholder = 'I hope you enjoy this gift!'

export const prepareBasketItem = (item: IBasketItem): IPreparedItem => {
  const {
    giftItem,
    quantity,
    individuals,
    recipient,
    value,
    questionResponses,
  } = item

  // TODO these are listed out because they are required, instead actually return false from this
  // function if one is not found rather than just defaulting to "0" or an empty string
  const itemId = giftItem.id || 0
  const deliveryMethod = recipient.deliveryMethod || DeliveryMethod.Email
  const voucherFirstName = recipient.voucherFirstName || ''
  const voucherLastName = recipient.voucherLastName || ''

  const messageTo = recipient.messageTo ?? getToPlaceholder(voucherFirstName)
  const message = recipient.message ?? messagePlaceholder
  const messageFrom = recipient.messageFrom ?? ''

  return {
    itemId,
    quantity,
    individuals,
    value,
    messageTo,
    message,
    messageFrom,
    deliveryMethod,
    voucherFirstName,
    voucherLastName,
    questionResponses,
    ...recipient,
  }
}

export const getItemValue = (item: IBasketItem, addPostage = true): number => {
  const itemValue = item.value * item.quantity * item.individuals
  const isPost = item.recipient.deliveryMethod === DeliveryMethod.Post
  const postage = isPost && addPostage ? item.postage.cost : 0

  return itemValue + postage
}

export const getBasketValue = (items: IBasketItem[]): number => {
  const basketValue = items?.reduce(
    (runningTotal, item) => runningTotal + getItemValue(item),
    0,
  )
  return basketValue
}

export const getUniqueId = (): string => uuidv4()

export const prepareOrder = (
  basketItems: IBasketItem[],
  purchaser: IPurchaserData,
  organisationId: number,
  paymentReference?: string,
): IOrderPostData | false => {
  const {
    purchaserPhone,
    purchaserEmail,
    purchaserFirstName,
    purchaserLastName,
    purchaserInternational,
    purchaserAddressLineOne,
    purchaserAddressLineTwo,
    purchaserTown,
    purchaserPostcode,
    joinMailingList,
  } = purchaser

  const orderTotal = getBasketValue(basketItems)
  const items = basketItems.map((item) => prepareBasketItem(item))

  const { dialingCode, phoneNumber } = purchaserPhone || {}
  const code = dialingCode && dialingCode !== '44' ? `+${dialingCode} ` : ''
  const constructedPhoneNumber = phoneNumber ? `${code}${phoneNumber}` : ''

  return {
    items,
    purchaserEmail: purchaserEmail || '',
    purchaserFirstName: purchaserFirstName || '',
    purchaserLastName: purchaserLastName || '',
    purchaserPhone: constructedPhoneNumber,
    purchaserInternational: purchaserInternational || false,
    purchaserAddressLineOne: purchaserAddressLineOne || '',
    purchaserAddressLineTwo: purchaserAddressLineTwo || '',
    purchaserTown: purchaserTown || '',
    purchaserPostcode: purchaserPostcode || '',
    joinMailingList: joinMailingList || false,
    orderTotal,
    organisationId,
    paymentReference,
  }
}

export const getEmailItems = (items: IBasketItem[]): IBasketItem[] => {
  const filtered = items.filter(
    (item) => item.recipient.deliveryMethod === DeliveryMethod.Email,
  )
  return filtered
}

export const getPostageItems = (items: IBasketItem[]): IBasketItem[] => {
  const filtered = items.filter(
    (item) => item.recipient.deliveryMethod === DeliveryMethod.Post,
  )
  return filtered
}

export const getPurchaserPostageItems = (
  items: IBasketItem[],
): IBasketItem[] => {
  const filtered = items.filter((item) => {
    const isPost = item.recipient.deliveryMethod === DeliveryMethod.Post
    const isPurchaser = item.recipient.recipientType === RecipientType.Purchaser
    return isPost && isPurchaser
  })
  return filtered
}

export const removeInvalid = (items: IBasketItem[]): IBasketItem[] => {
  const withoutInvalid = items.filter((item) => isItemValid(item))
  return withoutInvalid
}

export const isNotLastItem = (
  basketItems: IBasketItem[],
  organisation: IOrganisationResponse,
): boolean => {
  const { items } = organisation

  const item = items[0]
  const basketItem = basketItems[0]

  const remaining = getRemaining(item?.quantity, item?.quantitySold)
  const maxAllowed = getMaxQuantity(remaining, item?.maxPerOrder)

  const isLast = items.length === 1 && basketItem.quantity >= maxAllowed
  return !isLast
}

export const hasSomePurchaser = (basketItems: IBasketItem[]): boolean => {
  const result = basketItems.some((item) => {
    const isPurchaser = item.recipient.recipientType === RecipientType.Purchaser
    return isPurchaser
  })
  return result
}

export const hasAllPrint = (basketItems: IBasketItem[]): boolean => {
  const result = !basketItems.some((item) => {
    const { deliveryMethod } = item.recipient
    return deliveryMethod !== DeliveryMethod.Print
  })
  return result
}

export const hasSomePrint = (basketItems: IBasketItem[]): boolean => {
  const result = basketItems.some((item) => {
    const { deliveryMethod } = item.recipient
    return deliveryMethod === DeliveryMethod.Print
  })
  return result
}

export const hasAllEmail = (basketItems: IBasketItem[]): boolean => {
  const result = !basketItems.some((item) => {
    const { deliveryMethod } = item.recipient
    return deliveryMethod !== DeliveryMethod.Email
  })
  return result
}

export const hasSomeEmail = (basketItems: IBasketItem[]): boolean => {
  const result = basketItems.some((item) => {
    const { deliveryMethod } = item.recipient
    return deliveryMethod === DeliveryMethod.Email
  })
  return result
}

export const hasAllPost = (basketItems: IBasketItem[]): boolean => {
  const result = !basketItems.some((item) => {
    const { deliveryMethod } = item.recipient
    return deliveryMethod !== DeliveryMethod.Post
  })
  return result
}

export const hasSomePost = (basketItems: IBasketItem[]): boolean => {
  const result = basketItems.some((item) => {
    const { deliveryMethod } = item.recipient
    return deliveryMethod === DeliveryMethod.Post
  })
  return result
}

export function getNameIfPrice(
  giftItem: IGiftItemResponse,
): string | undefined {
  const thisPrice = giftItem.prices && giftItem.prices.find((price) => price.id === giftItem.id)
  return thisPrice?.name ?? undefined
}

export function addQuestionsIfPrice(
  giftItem: IGiftItemResponse,
): IGiftItemResponse {
  // If Prices, replace this Item's questions with matching price.questions
  const thisPrice = giftItem.prices && giftItem.prices.find((price) => price.id === giftItem.id)

  return {
    ...giftItem,
    questions: thisPrice?.questions ?? giftItem.questions,
  }
}
