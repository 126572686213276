import { ReactElement } from 'react'
import { Address } from 'react-loqate'

export interface ISimpleAddress {
  lineOne: string | undefined
  lineTwo: string | undefined
  town: string | undefined
  postcode: string | undefined
}

export type AddressListItem = ReactElement<{
  suggestion: { Type: string; Text: string }
  value: string
}>
export const isInexactPostcode = (listItem: AddressListItem): boolean => {
  const { suggestion, value } = listItem.props

  const notAlphaNumeric = /\W/g
  const userInput = value.toUpperCase().replace(notAlphaNumeric, '')

  const isPostcode = suggestion.Type === 'Postcode'
  const isNotExact = !suggestion.Text.toUpperCase()
    .replace(notAlphaNumeric, '')
    .includes(userInput)

  if (isPostcode && isNotExact) return true
  return false
}

export const getAddress = (loqateAddress: Partial<Address>): ISimpleAddress => {
  const {
    Line1,
    Line2,
    City,
    PostalCode,
    Company,
    BuildingName,
    BuildingNumber,
    SubBuilding,
    Street,
  } = loqateAddress

  let addressLine1 = ''
  let addressLine2 = ''

  // Construct the first line out of the building details
  addressLine1 = [
    Company && (SubBuilding || BuildingName) ? `${Company},` : Company,
    SubBuilding && BuildingName ? `${SubBuilding},` : SubBuilding,
    BuildingName,
  ]
    .filter(Boolean)
    .join(' ')

  // Construct the second line out of the Street details
  addressLine2 = [BuildingNumber, Street].filter(Boolean).join(' ')

  // If there are no Street details, use the provided second line
  if (!addressLine2) addressLine2 = Line2 || ''

  // If there are no building details, use the second line as the first line
  if (!addressLine1) {
    addressLine1 = addressLine2
    addressLine2 = ''
  }

  // If we still have no address, use the provided first line
  if (!addressLine1) addressLine1 = Line1 || ''

  return {
    lineOne: addressLine1,
    lineTwo: addressLine2,
    town: City,
    postcode: PostalCode,
  }
}
