import { Loading } from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { placeOrder, verifyTrust } from '../../helpers/APIHelper'
import { prepareOrder } from '../../helpers/basketHelper'
import { IPaymentProps } from '../../types/propTypes'
import { RoutePath } from '../../types/routes'
import { PageText } from '../page/PageElements'
import PageNavigation from '../page/PageNavigation'

const Form = styled.form`
  .field {
    display: flex;
    flex-direction: column;
    margin: auto;

    /* Force Trust's fields to stay within the IFrame */
    @media (max-width: 310px) {
      width: 270px;
    }
    @media (max-width: 290px) {
      width: 250px;
    }
    @media (max-width: 270px) {
      width: 230px;
    }
    @media (max-width: 250px) {
      width: 210px;
    }
  }
`

export default function Trust(props: IPaymentProps): ReactElement {
  const {
    basketItems,
    purchaser,
    addError,
    clearErrors,
    organisation,
    saveOrder,
    uniqueId,
  } = props

  const [trustLoaded, setTrustLoaded] = useState(false)
  const [paymentKey, setPaymentKey] = useState<string | false>(false)
  const history = useHistory()

  useEffect(() => {
    (async (): Promise<void> => {
      if (!organisation?.id) return

      const data = prepareOrder(basketItems, purchaser, organisation.id)
      if (!data) {
        addError('Error with order')
        return
      }

      const res = await placeOrder(data, uniqueId)

      if (res.error) {
        addError(res.error)
        return
      }
      if (!res.data) {
        addError('No response from server')
        return
      }

      saveOrder(res.data)
      setPaymentKey(res.data.paymentKey)
    })()
  }, [])

  const submitCallback = async (data: { jwt: string }): Promise<void> => {
    clearErrors()

    const { jwt } = data
    const res = await verifyTrust(jwt)

    if (res.error) {
      addError(res.error)
      return
    }
    if (!res.data) {
      addError('No response from server')
      return
    }

    // success!
    history.push(RoutePath.Confirmation)
  }

  const detectTrustLoaded = (): void => {
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          setTrustLoaded(true)
          observer.disconnect()
        }
      })
    })

    const targetNode = document.getElementById('st-security-code')
    if (targetNode) {
      observer.observe(targetNode, { childList: true })
    }
  }

  useEffect(() => {
    if (!paymentKey) return undefined

    const script = document.createElement('script')
    script.src = 'https://cdn.eu.trustpayments.com/js/latest/st.js'

    script.onload = (): void => {
      // @ts-ignore - we know SecureTrading will be here
      const trust = window.SecureTrading({
        jwt: paymentKey,
        submitOnSuccess: false,
        submitOnError: false,
        submitOnCancel: false,
        submitCallback,
        livestatus:
          process.env.REACT_APP_TRUST_PAYMENTS_LIVE === 'true' ? 1 : 0,
      })

      trust.Components()
    }

    detectTrustLoaded()

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [paymentKey])

  const renderForm = (
    <Form id="st-form" onSubmit={(event): void => event.preventDefault()}>
      <div id="st-card-number" className="field card" />
      <div id="st-expiration-date" className="field expiry" />
      <div id="st-security-code" className="field cvv" />
      {/* Trust hijacks the forward button and displays "Pay" */}
      <PageNavigation
        back={RoutePath.Basket}
        submit={true}
        className="buttons"
        forwardLabel="Pay"
      />
    </Form>
  )

  return (
    <>
      <PageText>
        Add your payment card details securely. You may be redirected to your
        bank for further confirmation.
      </PageText>
      {(!paymentKey || !trustLoaded) && <Loading />}
      {renderForm}
    </>
  )
}
