import {
  Button,
  ButtonSize,
  FieldSize,
  FieldStyles,
  InputField,
  InputType,
  stripEmptyFromArray,
  theme,
} from '@one-tree/library'
import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState,
} from 'react'
import AddressSearch, { Address } from 'react-loqate'
import styled from 'styled-components'
import 'react-loqate/dist/react-loqate.cjs.development.css'
import { FiSearch } from 'react-icons/fi'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  AddressListItem,
  getAddress,
  ISimpleAddress,
  isInexactPostcode,
} from './addressFinderHelper'

const Styles = styled.div`
  display: grid;
  gap: 12px;
  .dropdown {
    max-height: 400px;
    ${theme.scrollbar};
    overflow-y: auto;
  }
  .dropdown-item {
    white-space: normal;
  }
  .no-results {
    padding: 8px;
  }
  .search-disabled {
    pointer-events: none;
    opacity: 0.65;
  }
`
type AddressSearchInputType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
function AddressSearchInput(props: AddressSearchInputType): ReactElement {
  return (
    <div style={{ position: 'relative' }}>
      <input
        className="styled-input"
        placeholder="Search address or postcode..."
        autoComplete="chrome-off"
        {...props}
      />
      <div className="input-icon">
        <FiSearch />
      </div>
    </div>
  )
}

type AddressListProps = PropsWithChildren<{
  children?: ReactNode
}>
function AddressList(props: AddressListProps): ReactElement {
  // react-loqate uses the vague "ReactNode", but really we get an array of elements
  // eslint-disable-next-line
  const { children } = props as {
    children?: AddressListItem[]
  }

  const results = children
    && children.map((listItem: AddressListItem) => {
      if (isInexactPostcode(listItem)) return null
      return listItem
    })

  const hasResults = results && stripEmptyFromArray(results).length

  return (
    <ul {...props} className="react-loqate-default-list dropdown">
      {hasResults ? results : <li className="no-results">No results found</li>}
    </ul>
  )
}

interface IAddressFinderProps extends ISimpleAddress {
  setLineOne: (lineOne: string) => void
  setLineTwo: (lineTwo: string) => void
  setTown: (town: string) => void
  setPostcode: (postcode: string) => void
  isError?: boolean
  disabled?: boolean
}
function AddressFinder(props: IAddressFinderProps): ReactElement {
  const {
    lineOne,
    setLineOne,
    lineTwo,
    setLineTwo,
    town,
    setTown,
    postcode,
    setPostcode,
    isError,
    disabled,
  } = props

  const [hasSelected, setHasSelected] = useState(false)

  const handleSelectAddress = (loqateAddress: Address): void => {
    const address = getAddress(loqateAddress)

    setLineOne(address.lineOne || '')
    setLineTwo(address.lineTwo || '')
    setTown(address.town || '')
    setPostcode(address.postcode || '')

    setHasSelected(true)
  }

  const { buttonColors } = useOrganisation()

  const renderSearch = hasSelected ? (
    <Button
      onClick={(): void => setHasSelected(false)}
      customColors={buttonColors}
      disabled={disabled}
      buttonSize={ButtonSize.Large}
    >
      Search again
    </Button>
  ) : (
    <AddressSearch
      locale="en_GB"
      apiKey={process.env.REACT_APP_LOQATE_KEY}
      countries={['GB']}
      components={{
        Input: AddressSearchInput,
        List: AddressList,
      }}
      limit={10}
      className={`address-search-box ${disabled ? 'search-disabled' : ''}`}
      classes={{
        input: 'styled-input',
        list: 'dropdown',
        listItem: 'dropdown-item',
      }}
      onSelect={handleSelectAddress}
      inline={true}
      debounce={100}
    />
  )

  return (
    <Styles>
      <FieldStyles fieldSize={FieldSize.Large}>{renderSearch}</FieldStyles>
      <InputField
        type={InputType.Text}
        placeholder="Address line one"
        value={lineOne}
        onChange={setLineOne}
        isError={isError}
        disabled={disabled}
        autoComplete="address-line1"
        fieldSize={FieldSize.Large}
        maxChars={128}
        hideMaxChars={true}
      />
      <InputField
        type={InputType.Text}
        placeholder="Address line two"
        value={lineTwo}
        onChange={setLineTwo}
        disabled={disabled}
        autoComplete="address-line2"
        fieldSize={FieldSize.Large}
        maxChars={128}
        hideMaxChars={true}
      />
      <InputField
        type={InputType.Text}
        placeholder="Town / city"
        value={town}
        onChange={setTown}
        isError={isError}
        disabled={disabled}
        autoComplete="address-line3"
        fieldSize={FieldSize.Large}
        maxChars={45}
        hideMaxChars={true}
      />
      <InputField
        type={InputType.Text}
        placeholder="Postcode"
        value={postcode}
        onChange={setPostcode}
        isError={isError}
        disabled={disabled}
        autoComplete="postal-code"
        fieldSize={FieldSize.Large}
        maxChars={12}
        hideMaxChars={true}
      />
    </Styles>
  )
}
export default AddressFinder
