import EmailValidator from 'email-validator'
import { isValidPhoneNumber } from 'libphonenumber-js'
import isValid from 'uk-postcode-validator'
import { IPurchaserData } from '../../../types/dataTypes'

export const purchaserEmailTest = (
  purchaser: IPurchaserData | undefined,
  required: boolean,
): string | false => {
  if (!required) return false

  const { purchaserEmail } = purchaser || {}

  if (!purchaserEmail) {
    return 'Requires purchaser email'
  }
  if (purchaserEmail && !EmailValidator.validate(purchaserEmail)) {
    return 'Purchaser email invalid'
  }
  return false
}

export const purchaserEmailConfirmTest = (
  purchaser: IPurchaserData | undefined,
  required: boolean,
): string | false => {
  if (!required) return false

  const { purchaserEmail } = purchaser || {}
  const { purchaserEmailConfirm } = purchaser || {}

  if (purchaserEmail !== purchaserEmailConfirm) {
    return 'Email confirmation does not match'
  }

  return false
}

export const purchaserFirstNameTest = (
  purchaser: IPurchaserData | undefined,
  required: boolean,
): string | false => {
  if (required && !purchaser?.purchaserFirstName) {
    return 'Requires purchaser first name'
  }
  return false
}

export const purchaserLastNameTest = (
  purchaser: IPurchaserData | undefined,
  required: boolean,
): string | false => {
  if (required && !purchaser?.purchaserLastName) {
    return 'Requires purchaser last name'
  }
  return false
}

export const purchaserPhoneTest = (
  purchaser: IPurchaserData | undefined,
  required: boolean,
): string | false => {
  const { purchaserPhone } = purchaser || {}
  if (required && !purchaserPhone?.phoneNumber) {
    return 'Requires phone number'
  }
  if (
    purchaserPhone
    && purchaserPhone?.phoneNumber
    && !isValidPhoneNumber(
      `+${purchaserPhone.dialingCode}${purchaserPhone.phoneNumber}`,
    )
  ) {
    return 'Phone number is invalid'
  }
  return false
}

export const purchaserTermsTest = (
  purchaser: IPurchaserData | undefined,
  required: boolean,
): string | false => {
  if (required && !purchaser?.agreedToTerms) {
    return 'Must agree to terms'
  }
  return false
}

export const purchaserAddressTest = (
  purchaser: IPurchaserData | undefined,
  required: boolean,
): string | false => {
  if (!required) return false

  const {
    purchaserInternational,
    purchaserAddressLineOne,
    purchaserTown,
    purchaserPostcode,
  } = purchaser || {}

  const addressIncomplete = !purchaserAddressLineOne
    || !purchaserTown
    || (!purchaserInternational && !purchaserPostcode)

  if (addressIncomplete) {
    return 'Purchaser address incomplete'
  }

  if (!purchaserPostcode || !isValid(purchaserPostcode)) {
    return 'UK postcode invalid'
  }

  return false
}
