import React, { ReactElement } from 'react'
import { useBasket } from '../../context/BasketProvider'
import { useError } from '../../context/ErrorProvider'
import { placeOrder } from '../../helpers/APIHelper'
import { prepareOrder } from '../../helpers/basketHelper'
import { IPaymentProps } from '../../types/propTypes'
import { RoutePath } from '../../types/routes'
import PageNavigation from '../page/PageNavigation'

export default function Free(props: IPaymentProps): ReactElement {
  const {
    basketItems, purchaser, addError, organisation, uniqueId,
  } = props
  const { setShowErrors } = useError()
  const { saveOrder } = useBasket()

  const handleOrder = async (): Promise<boolean> => {
    setShowErrors(true)

    if (!organisation) return false
    const data = prepareOrder(basketItems, purchaser, organisation.id)
    if (!data) {
      addError('Error with order')
      return false
    }
    const res = await placeOrder(data, uniqueId)

    if (res.error) {
      addError(res.error)
      return false
    }
    if (!res.data) {
      addError('No response from server')
      return false
    }

    saveOrder(res.data)
    return true
  }

  return (
    <PageNavigation
      back={RoutePath.Basket}
      forward={RoutePath.Confirmation}
      forwardLabel="Complete order"
      onForward={handleOrder}
    />
  )
}
